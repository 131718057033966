import React from 'react';

import {Button, useMutation, useNotify, useRedirect} from 'react-admin'
import {connect} from "react-redux";
import _ from 'lodash';

function cleanPayroll (payRun) {
    payRun.runDate = new Date()
   const payrolls = payRun.payrolls.map(p=>_.omit(p,["loans","leaveDaysTakenPrevious",'grossIncome','fixedDeductionsTotalsAndIncomeTax','additionalFixedEarningsOriginal','payrollDate','periodDaySetting', 'costCenterCosts', 'useServiceHours', 'deductServiceHour', 'daysInMonthForOT', 'daysInMonthForExpectedHours', 'employee','startDate', 'contractEndDate', 'distEarningsOnActualDateSetting']))
    return {..._.omit(payRun,['prevPayrollDate','firstRun','excluded', 'settings']),payrolls}
}

const SavePayrollButton = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [approve, { loading }] = useMutation(
        {
            type: 'create',
            resource: 'payrollRuns',
            payload: { data: cleanPayroll(props.runPayrollData) },
        },
        {
            action: 'PAYROLL_RUN_SAVED',
            onSuccess: ({ data }) => {
                redirect(`/runPayroll/${data.id}/reports`);
                notify('Payroll Saved Successfully');
            },
            onFailure: (error) => {
                //console.log(error)
                notify(`Payroll can not saved right now, Please check the data or press re-set and try again`, 'warning')
            },
        }
    );
    return <Button  variant="contained" size="large" color={props.disabled?"secondary":"primary"} label="Save & Continue" onClick={approve} disabled={loading}  />;

};

export default connect(state=>({runPayrollData:state.runPayroll}),dispatch=>{
    return {
        onSavePayroll:(runPayrollData) => {
         //   const {  incomeTaxRules, ...payrollRun } = runPayrollData;
            return dispatch({type:'SAVE_PAYROLL_RUN',payload:runPayrollData})
        },
    }
})(SavePayrollButton);
//export default SavePayrollButton
