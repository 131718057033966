import React, {useEffect, useRef, useState} from 'react';
import {Edit, Loading, SelectInput, SimpleForm, TextInput, useQueryWithStore} from 'react-admin'
import {
    Grid as KGrid,
    GridColumn as Column,
    GridColumnMenuCheckboxFilter,
    GridToolbar
} from '@progress/kendo-react-grid';
import {Button as KButton} from "@progress/kendo-react-buttons"
import {connect, useDispatch} from "react-redux";
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import AddOverTimeDialog from "../dialogs/addOverTime";
import CaptionedField from "../../UIComponents/captionedField";
import AddBonusPopover from "../dialogs/addBonusPopover";
import EarningsPopover from "../dialogs/earningsPopover";
import DeductionsPopover from "../dialogs/deductionsPopover";
import AddPayrollDeductionPopover from "../dialogs/addPayrollDeductionPopover";
import moment from "moment";
import PayrollExcelExport from "./excelExport";
import MultiCurrency from "../multiCurrency";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {DatePicker} from "@material-ui/pickers";
import {filterBy, orderBy} from '@progress/kendo-data-query';
import Divider from "@material-ui/core/Divider";
import SetTimeSheetDialog from "../dialogs/editTimeSheet";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardContent from "@material-ui/core/CardContent";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import {Typography} from "../../UIComponents/Wrappers";
import NumberFormat from 'react-number-format';
import AddBonusDialog from "../dialogs/bonusDialog";
import {MultiCurrencyContext} from "../../contexts";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import SyncMatesDialog from "../dialogs/syncMatesDialog";
import {multiDateFun} from "../../Helpers/multiDateFun";
import DistEarnings from "../dialogs/distEarnings";
import ImportEarningsDeductionsFromFile from "./importEarningsAndDeductions";
import {DateRangePicker} from "@progress/kendo-react-dateinputs";
import Values from 'values.js'
import MergePayrollDialog from "../dialogs/MergePayrollDialog";
import GroupBonus from "../dialogs/groupBonusDialog";

const expand = props => {
    return <div>
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="name"/>
            </SimpleForm>
        </Edit>
    </div>
}

function sort(x) {

}

// class DetailComponent extends GridDetailRow {
//     render() {
//         const dataItem = this.props.dataItem;
//         return (
//             <section>
//                 <p><strong>Basic Salary</strong> {dataItem.employee.basicSalary}</p>
//                <TextField value={dataItem.daysWorked} onChange={e=>console.log(e.target.value)}/>
//              </section>
//         );
//     }
// }

const getColor = (type) => {
    switch (type) {
        case 'attention':
            return 'red'

        case 'warning':
            return 'orange'
        default:
            return 'black'
    }
}
const color = new Values('hsl(44deg 98% 50% / 1)')
const MultiCurrencyCell = (props) => {
    const value = props.dataItem[props.field];
    const attention = (props.field === "additionalEarningsTotal" && props.dataItem['additionalFixedEarningsOriginal'] && props.dataItem['additionalFixedEarningsOriginal'].toFixed(2) !== props.dataItem['additionalFixedEarnings'].toFixed(2)) ||
        // (props.field === "cash" &&   props.dataItem['fullBasicSalary'] && props.dataItem['fullBasicSalary'].toFixed(2) !==  props.dataItem['basicSalary'].toFixed(2))||
        (props.field === "totalDeductionIncludingCash" && props.dataItem['fixedDeductionsTotals'] && props.dataItem['fixedDeductionsTotals'].toFixed(2) !== props.dataItem['payrollFixedDeductions'].reduce((sum, cv) => sum + cv.originalValue, 0).toFixed(2)) ||
        (props.field === "basicSalary" && props.dataItem['fullBasicSalary'] && props.dataItem['fullBasicSalary'].toFixed(2) !== props.dataItem['basicSalary'].toFixed(2)) ||
        (props.field === "cash" && props.dataItem['cash'] < 0)

    ;
    const warn = (props.field === "totalDeductionIncludingCash" && props.dataItem['basicAbsentDeduction'] > 0)

    return <td style={{
        color: props.attention || attention ? 'red' : 'black',
        backgroundColor: warn ? color.tint((props.dataItem['cash'] * 50) / (props.dataItem['cash'] + props.dataItem['basicAbsentDeduction'])).hexString() : 'transparent'
    }}><MultiCurrency value={value}/><span style={{fontSize: 'large'}}>{props.attention || attention ? "*" : ''}</span>
    </td>
}


const DetailPayroll = props => {
    // console.log(props)
    const {dataItem, onItemChange, overtimeTypes, isEdit, isApproved} = props


    return <Grid container spacing={3}>

        <Grid item xs={3}>
            {/*{dataItem.payrollDate.toString()}*/}
            <TextField fullWidth inputProps={{max: dataItem.totalDaysInPayPeriod, min: "0", step: "1"}} type="number"
                       key={dataItem.id}
                       label={`Normal Payable Days out of ${dataItem.totalDaysInPayPeriod} ${dataItem.originalPayDays && dataItem.originalPayDays !== dataItem.normalPayDays ? `* was ${dataItem.originalPayDays}` : ''}`}
                       value={dataItem.normalPayDays} onChange={e => onItemChange(
                {
                    dataItem,
                    field: 'normalPayDays',
                    value: +e.target.value
                })}/>

            <FormControlLabel control={<Checkbox
                checked={(dataItem.backPayDays + dataItem.forwardPayDays) > 0}
                color="primary"
                onChange={e => onItemChange(
                    {
                        dataItem,
                        field: 'backPayDays',
                        value: e.target.checked ? 1 : 0
                    })}
                inputProps={{'aria-label': 'primary checkbox'}}
            />} label={<Typography size='sm'>Extra Days</Typography>}/>


            {(dataItem.backPayDays + dataItem.forwardPayDays) > 0 && <Grid container>
                <Grid item xs={6}>
                    <TextField fullWidth inputProps={{max: dataItem.totalDaysInPayPeriod, min: "0", step: "1"}}
                               type="number" key={dataItem.id}
                               label={`Back Pay Days`}
                               value={dataItem.backPayDays} onChange={e => onItemChange(
                        {
                            dataItem,
                            field: 'backPayDays',
                            value: +e.target.value
                        })}/>
                </Grid>
                <Grid item xs={6}>

                    <TextField fullWidth inputProps={{max: dataItem.totalDaysInPayPeriod, min: "0", step: "1"}}
                               type="number" key={dataItem.id}
                               label={`Forward Pay Days`}
                               value={dataItem.forwardPayDays} onChange={e => onItemChange(
                        {
                            dataItem,
                            field: 'forwardPayDays',
                            value: +e.target.value
                        })}/>

                </Grid>
            </Grid>}
            {/*<p><strong>{dataItem.employmentType!=='Casual'?'Basic Salary':'Daily Wadge'}</strong> {dataItem.basicSalary}</p>*/}
            {/*    <CaptionedField label='payDays' value= {dataItem.payDays}/>*/}


            <CaptionedField label={dataItem.employmentType !== 'Casual' ? `Basic Salary of ` : 'Daily Wadge'}
                            labelValue={dataItem.fullBasicSalary} value={dataItem.basicSalary}/>


            {/*<TextField value={dataItem.daysWorked} onChange={e=>onChange({*/}
            {/*dataItem,*/}
            {/*field:'daysWorked',*/}
            {/*value:e.target.value*/}
            {/*})}/>*/}
            <TextField fullWidth inputProps={{max: dataItem.totalWorkDays, min: "0", step: "1"}} type="number"
                       key={"cdsds"} label={`Days Worked out of ${dataItem.totalWorkDays.toFixed(2)}`}
                       value={dataItem.daysWorked} onChange={e => onItemChange(
                {
                    dataItem,
                    field: 'daysWorked',
                    value: +e.target.value
                })}/>

            {/*<TextField fullWidth disabled={dataItem.fromTimeAttendance===dataItem.hoursWorked}  inputProps={{min:"0", step: "1" }}   type="number" key={dataItem.id} label={!(dataItem.fromTimeAttendance===dataItem.hoursWorked)?`Payable Hours of ${(dataItem.monthlyHours||0).toFixed(2)}`:'Payable Hours ⏰'} value={dataItem.hoursWorked} onChange={e=>onItemChange(*/}
            {/*    {*/}
            {/*        dataItem,*/}
            {/*        field:'hoursWorked',*/}
            {/*        value:+e.target.value*/}
            {/*    })}/>*/}

            <NumberFormat fullWidth key={dataItem.id} inputProps={{max: dataItem.totalWorkDays, min: "0", step: "1"}}
                          type="number" customInput={TextField} decimalScale={2} label="Expected Hours"
                          value={dataItem.monthlyHours} onChange={e => onItemChange(
                {
                    dataItem,
                    field: 'monthlyHours',
                    value: +e.target.value
                })}/>


            <NumberFormat fullWidth key={dataItem.id} inputProps={{max: dataItem.monthlyHours, min: "0", step: "1"}}
                          type="number" customInput={TextField} decimalScale={2}
                          label={!(dataItem.fromTimeAttendance === dataItem.hoursWorked) ? `Payable Hours of  ${(dataItem.monthlyHours || 0).toFixed(2)}` : 'Payable Hours ⏰'}
                          value={dataItem.hoursWorked} onChange={e => onItemChange(
                {
                    dataItem,
                    field: 'hoursWorked',
                    value: +e.target.value
                })}/>


            <NumberFormat fullWidth key={dataItem.id} inputProps={{max: dataItem.monthlyHours, min: "0", step: "1"}}
                          type="number" customInput={TextField} decimalScale={2}
                          label={!(dataItem.fromTimeAttendance === dataItem.hoursWorked) ? `Actual Hours of  ${(dataItem.monthlyHours || 0).toFixed(2)}` : 'Actual Hours ⏰'}
                          value={dataItem.actualHoursWorked} onChange={e => onItemChange(
                {
                    dataItem,
                    field: 'actualHoursWorked',
                    value: +e.target.value
                })}/>

            <NumberFormat fullWidth key={dataItem.id} inputProps={{min: "0", step: "1"}} type="number"
                          customInput={TextField}
                          label={`Avg Daily Hour, Service Hour = ${dataItem.daysWorked * dataItem.dailyServiceHour} ${dataItem.deductServiceHour ? `- ${dataItem.missedHours || 0} = ${dataItem.serviceHours}` : ''}`}
                          value={dataItem.dailyServiceHour} onChange={e => onItemChange(
                {
                    dataItem,
                    field: 'dailyServiceHour',
                    value: +e.target.value
                })}/>

            <CaptionedField label='Earned Salary (Working Hours or Days)' value={dataItem.earnedSalary}/>
            {/*<CaptionedField label='Outstanding Loan' value= {dataItem.outstandingLoan}/>*/}
            <Divider/>
            {isEdit && <FormControlLabel control={<Checkbox
                checked={dataItem.emailSent}
                color="primary"
                onChange={e => onItemChange(
                    {
                        dataItem,
                        field: 'emailSent',
                        value: e.target.checked
                    })}
                inputProps={{'aria-label': 'primary checkbox'}}
            />} label={<Typography size='sm'>Email is sent</Typography>}/>}

            {isEdit && <FormControlLabel control={<Checkbox
                checked={dataItem.publishId}
                color="primary"
                onChange={e => onItemChange(
                    {
                        dataItem,
                        field: 'publishId',
                        value: e.target.checked ? -1 : 0
                    })}
                inputProps={{'aria-label': 'primary checkbox'}}
            />} label={<Typography size='sm'>Published</Typography>}/>}


        </Grid>
        <Grid item xs={3}>
            <Grid container alignItems='center'>
                <Grid item>
                    <CaptionedField
                        label={`Fixed Earnings ${dataItem.additionalFixedEarningsOriginal && dataItem.additionalFixedEarnings.toFixed(2) !== dataItem.additionalFixedEarningsOriginal.toFixed(2) ? `* ${dataItem.additionalFixedEarningsOriginal.toFixed(2)}` : ''}`}
                        value={dataItem.additionalFixedEarnings}/>
                </Grid>
                <Grid item>
                    <EarningsPopover totalDaysInPayPeriod={dataItem.totalDaysInPayPeriod}
                                     earnings={dataItem.payrollFixedEarnings} onChange={items => onItemChange({
                        dataItem,
                        field: 'payrollFixedEarnings',
                        value: items
                    })}/>
                </Grid>

                {/*<FormControlLabel control={<Checkbox*/}
                {/*    checked={dataItem.omitTransportAllowance}*/}
                {/*    color="primary"*/}
                {/*    onChange={e=>onItemChange(*/}
                {/*        {*/}
                {/*            dataItem,*/}
                {/*            field:'omitTransportAllowance',*/}
                {/*            value:e.target.checked*/}
                {/*        })}*/}
                {/*    inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                {/*/>} label={<Typography size='sm' >Omit Transport Allowance</Typography>} />*/}


            </Grid>

            <CaptionedField label='Overtime Total' value={dataItem.overtimeTotal}/>

            <AddOverTimeDialog overtimeTypes={overtimeTypes} overtimes={dataItem.overtimes} onAdd={ot => onItemChange({
                dataItem,
                field: 'overtimes',
                value: ot
            })}/>
            <CaptionedField label='Extra Earnings Total' value={dataItem.bonusesTotal}/>
            <AddBonusDialog key={JSON.stringify(dataItem.bonuses)} payroll={dataItem} bonuses={dataItem.bonuses}
                            onAdd={ot => onItemChange({
                                dataItem,
                                field: 'bonuses',
                                value: ot
                            })}/>

            <Divider/>
            <Grid container alignItems='center'>
                <Grid item>
                    <CaptionedField
                        label={`Company Expenses ${dataItem['fixedExpensesTotals'].toFixed(2) !== dataItem['payrollFixedExpenses'].reduce((sum, cv) => sum + cv.originalValue, 0).toFixed(2) ? '*' : ''}`}
                        value={dataItem.fixedExpensesTotals}/>
                </Grid>
                <Grid item>
                    <DeductionsPopover deductions={dataItem.payrollFixedExpenses}/>
                </Grid>
            </Grid>
            {/*<AddBonusPopover onAdd={b=>onItemChange({*/}
            {/*    dataItem,*/}
            {/*    field:'bonuses',*/}
            {/*    value:[...dataItem.bonuses,b]*/}
            {/*})}/>*/}
        </Grid>
        <Grid item xs={3}>
            <Grid container alignItems='center'>
                <Grid item>
                    <CaptionedField
                        label={`Fixed Deduction ${dataItem['fixedDeductionsTotals'].toFixed(2) !== dataItem['payrollFixedDeductions'].reduce((sum, cv) => sum + cv.originalValue, 0).toFixed(2) ? '*' : ''}`}
                        value={dataItem.fixedDeductionsTotals}/>
                </Grid>
                <Grid item>
                    <DeductionsPopover deductions={dataItem.payrollFixedDeductions}/>
                </Grid>
            </Grid>
            <TextField  fullWidth inputProps={{max: "30", min: "0", step: "1"}} type="number" label={`Absent Days ${dataItem.penaltyAbsentDays?`${dataItem.penaltyAbsentDays} Penalty Days`:''}`}
                       value={dataItem.absentDays} onChange={e => onItemChange(
                {
                    dataItem,
                    field: 'absentDays',
                    value: +e.target.value
                })}/>

            <CaptionedField label='Absent Deduction' value={dataItem.basicAbsentDeduction}/>

            <TextField fullWidth inputProps={{max: "30", min: "0", step: "1"}} type="number"
                       label={`Leave Days ${dataItem.leaveDaysTaken}/${dataItem.leaveDaysEntitled}`}
                       value={dataItem.leaveDays} onChange={e => onItemChange(
                {
                    dataItem,
                    field: 'leaveDays',
                    value: +e.target.value
                })}/>

            <Grid container>

                <FormControlLabel control={<Checkbox
                    checked={dataItem.deductTransportIfAbsent}
                    color="primary"
                    onChange={e => onItemChange(
                        {
                            dataItem,
                            field: 'deductTransportIfAbsent',
                            value: e.target.checked
                        })}
                    inputProps={{'aria-label': 'primary checkbox'}}
                />} label={<Typography size='sm'>Deduction from Additional Earnings</Typography>}/>


                {dataItem.deductTransportIfAbsent &&
                <CaptionedField label='' value={dataItem.additionalEarningsAbsentDeduction}/>
                }
            </Grid>

            <CaptionedField label='Taxable Income' value={dataItem.taxableIncome}/>
            <CaptionedField label='Income Tax' value={dataItem.incomeTax}/>

            <CaptionedField label='Loan Settlement/other Deductions'
                            value={dataItem.payrollDeductionTotal + dataItem.payrollDeductionTotalCash}/>
            {/*<CaptionedField label='Loan' value={dataItem.payrollLoan}/>*/}
            <AddPayrollDeductionPopover loans={dataItem.loans} payrollDeductions={dataItem.payrollDeductions}
                                        onAdd={ot => onItemChange({
                                            dataItem,
                                            field: 'payrollDeductions',
                                            value: ot
                                        })}/>
            {/*({dataItem.absentDays} days)*/}

            {/*<p>Absence <strong>{dataItem.absentDays}</strong> days    <strong>{dataItem.absenceDeduction}</strong></p>*/}
        </Grid>
        <Grid item xs={3}>
            <CaptionedField
                label={dataItem.employmentType !== 'Casual' ? `+Basic Salary ${dataItem.payDays != dataItem.totalDaysInPayPeriod ? `(${dataItem.payDays.toFixed(2)} days)` : ''}` : 'Daily Wadge'}
                value={dataItem.basicSalary}/>
            {dataItem.employmentType === 'Casual' &&
            <CaptionedField label={`X ${dataItem.daysWorked} Days`} value={dataItem.earnedSalary}/>}
            <CaptionedField label='+Additional Earnings' value={dataItem.additionalEarningsTotal}/>
            <Divider/>
            <CaptionedField label='Total Income' value={dataItem.totalIncome}/>
            <CaptionedField label='-Deductions' value={dataItem.totalDeduction}/>
            <Divider/>
            <CaptionedField label='Net' value={dataItem.netPay}/>
            <CaptionedField label='-Settlement' value={dataItem.payrollDeductionTotalCash}/>
            <Divider/>
            <CaptionedField label='Net Pay' value={dataItem.cash}/>
            {dataItem.costCenterItems && dataItem.costCenterItems.length && <div>Cost Centers</div>}
            {dataItem.costCenterItems && dataItem.costCenterItems.map(csi =>
                <CaptionedField label={csi.costCenterCode} value={csi.cash}/>)}
            {/*<p><strong>{dataItem.deductions}</strong></p>*/}
        </Grid>
        <Grid item xs={12}>
            <FormControl component="fieldset">
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup row defaultValue="Processing" aria-label="gender" name="gender1" value={dataItem.status}
                            onChange={e => onItemChange(
                                {
                                    dataItem,
                                    field: 'status',
                                    value: e.target.value
                                })}>
                    <FormControlLabel labelPlacement="top" value="Processing" control={<Radio color="primary"/>}
                                      label="Processing"/>
                    <FormControlLabel labelPlacement="top" value="Paid" control={<Radio color="primary"/>}
                                      label="Paid"/>
                    <FormControlLabel labelPlacement="top" value="Held" control={<Radio color="primary"/>}
                                      label="Held"/>
                </RadioGroup>
            </FormControl>
        </Grid>
    </Grid>
}

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

let tempSet = false;
const RunPayroll = props => {
    //console.log(props)
    const id = +props.id;
    const exportExcel = useRef()

    const {payrolls: payrollData, site, employmentType, description, payrollStatus, firstRun, costCenter} = props.runPayroll


    // const [site, setSite] = useState(0);
//console.log(payrollData)
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log(props.runPayroll)
        if (payrollData.length) {
            dispatch({type: 'PAYROLLRUN_SET_FIRST_RUN', payload: false});
        }

        if (!id && payrollData.length === 0) {
            dispatch({type: 'LOAD_PAYROLL_EMPLOYEES'});

        }
        if (id) {
            //  console.log(id)
            setFilter(undefined);
            dispatch({type: 'CLEAR_PAYROLL'})
            dispatch({type: 'LOAD_PAYROLLRUN', payload: {id}})
            // setFilter(site?  {filters:[{field: "siteName", operator: "eq", value: site}],logic:"and"}: {filters:[],logic:"and"})
        }


    }, [])

    useEffect(() => {
        setFilter(site ? {filters: [{field: "siteName", operator: "eq", value: site}], logic: "and"} : {
            filters: [],
            logic: "and"
        })

    }, [site])


    const {data: OvertimeTypes} = useQueryWithStore({
        type: 'getList',
        resource: 'overtimeTypes'
    });

    const {data: sites} = useQueryWithStore({
        type: 'getList',
        resource: 'sites',
        payload: {filter: {}, pagination: {}, sort: {}}
    });

    const {data: costCenters} = useQueryWithStore({
        type: 'getList',
        resource: 'costCenters',
        payload: {filter: {}, pagination: {}, sort: {}}
    });

    const employmentTypes = [
        { id: 'FullTime', name: 'FullTime' },
        { id: 'Contract', name: 'Contract' },
        { id: 'PartTime', name: 'PartTime' },
        { id: 'InternOrFellow', name: 'InternOrFellow' },
        { id: 'Casual', name: 'Casual' },
    ]
    const payrollStates = [
        {id: 'Processing', name: 'Processing'},
        {id: 'Paid', name: 'Paid'},
        {id: 'Held', name: 'Held'},
    ]
    const [editID, setEditID] = useState(null);
    const forceUpdate = useForceUpdate();
    const [sort, setSort] = useState([
        {field: 'fullName', dir: 'asc'}
    ]);


    const [filter, setFilter] = useState(site ? {
        filters: [{field: "siteName", operator: "eq", value: site}],
        logic: "and"
    } : {filters: [], logic: "and"});
    // console.log({site,filter})
    const [additionalFilters, setAdditionalFilters] = useState([])
    const [nameFilter, setNameFilter] = useState(null)

    const [periodRange, setPeriodRange] = React.useState({
        start: moment(props.runPayroll.periodStart).toDate(),
        end: moment(props.runPayroll.periodEnd).toDate()
    });
    if (!tempSet && (moment(props.runPayroll.periodStart).format('YYYY-MM-DD') !== moment(periodRange.start).format('YYYY-MM-DD') ||
        moment(props.runPayroll.periodEnd).format('YYYY-MM-DD') !== moment(periodRange.end).format('YYYY-MM-DD')
    )) {
        setPeriodRange({
            start: moment(props.runPayroll.periodStart).toDate(),
            end: moment(props.runPayroll.periodEnd).toDate()
        })
    }
    //const payrollData = [{id:1,name:'x',place:'cast'},{id:2,name:'y',place:'las'},{id:74,name:'z',place:'tas'}]
    const columns = [
        {key: 'name', name: 'name', sortable: true},
        {key: 'place', name: 'Place', sortable: true}
    ];
    const reset = () => {
        dispatch({type: 'LOAD_PAYROLL_EMPLOYEES', payload: {refresh: false}})
        setFilter(undefined);
    };

    const refresh = () => dispatch({type: 'LOAD_PAYROLL_EMPLOYEES', payload: {refresh: true}});

    const exportToExcel = () => {
        exportExcel.current.save();
    }

    const [filteredPayroll, setFilteredPayroll] = useState([])
    const [markAllPublished,setMarkAllPublished] = useState(false)
    const [markAllSent,setMarkAllSent] = useState(false)
    useEffect(() => {
        setFilteredPayroll(filterBy(orderBy(payrollData.map((p) => {
                const item = {}
                item.inEdit = p.id === editID
                item.totalDeductionIncludingCash = p.totalDeductionIncludingCash || p.totalDeduction + p.payrollDeductionTotalCash

                if (costCenter && p.costCenterItems && p.costCenterItems.length) {
                    const cs = p.costCenterItems.find(c => c.costCenterCode === costCenter)
                    // console.log(cs)
                    if (cs) {
                        item.cash = cs.cash
                        item.costCenterCode = cs.costCenterCode
                        item.totalIncome = cs.totalIncome
                        item.netPay = cs.netPay
                        item.incomeTax = cs.incomeTax
                        item.taxableIncome = cs.taxableIncome
                    }
                }

                return {...p, ...item}
            }
        ), sort), filter))
    }, [payrollData, filter])


    const TotalCell = (props) => {
        const sum = filteredPayroll.reduce((acc, current) => acc + current[props.field], 0);
        const count = filteredPayroll.length
        //const max = products.reduce((acc, current) => Math.max(acc, current[props.field]), 0);
        return (
            <td colSpan={props.colSpan} style={{fontSize: 'small'}}>
                {props.type === "count" ? count : (+sum.toFixed(2)).toLocaleString()}
            </td>
        );
    }
    const handlePeriodChange = (ev) => {
        const range = ev.target.value;
        tempSet = true
        setPeriodRange(range)
        if (range && range.start && range.end) {
            props.onPayrollDateChanged({periodStart: range.start, periodEnd: range.end})
            tempSet = false
        }
    }


    // if (props.loading) { return <Loading />; }

    const handleNameFilterChange = (e) => {
        console.log(e.filter)
        setNameFilter(e.filter)
    }

    const handleFieldChange = (field,val) => {
        dispatch({
            type: 'PAYROLLS_SET_FIELD',
            payload: {field,val}
        })
    }
    return (
        <MultiCurrencyContext.Provider value={props.runPayroll.currencyRate}>
            <div>
                {/*{props.runPayroll.periodStart}*/}
                {/*From {multiDateFun(props.runPayroll.calendar,moment(props.runPayroll.periodStart).toDate(),'MMMM D') } to {multiDateFun(props.runPayroll.calendar,moment(props.runPayroll.periodEnd).toDate(),'MMMM D')}*/}
                <DateRangePicker value={periodRange} format={'MMMM d'} onChange={handlePeriodChange}/>


                {/*<DatePicker*/}
                {/*    label="From"*/}
                {/*    margin="dense"*/}
                {/*    value={props.runPayroll.periodStart} onChange={periodStart=>props.onPayrollDateChanged({periodStart})} />*/}

                {/*<DatePicker*/}
                {/*    label="To"*/}
                {/*    margin="dense"*/}
                {/*    value={props.runPayroll.periodEnd} onChange={periodEnd=>props.onPayrollDateChanged({periodEnd})}  />*/}

                <PayrollExcelExport ref={exportExcel}
                                    data={filterBy(orderBy(payrollData.filter(p => !site || p.siteName === site), sort), filter).map((item, i) =>
                                        ({...item, sn: i + 1})
                                    )}
                                    fileName={`PayDay ${props.companyName} Run ${moment(props.runPayroll.payrollDate).format('MMMM YYYY')} ${site || ''}.xlsx`}/>


                <KGrid style={{maxHeight: '120vh'}} editField="inEdit"
                       resizable
                       detail={ConnectedDetail}
                       expandField="expanded"
                       onExpandChange={props.onExpandChange}
                       onItemChange={props.onItemChange}
                       data={filteredPayroll}
                       onRowClick={event => setEditID(event.dataItem.id)}
                       sortable
                       filter={nameFilter}
                       sort={sort}
                       onSortChange={(e) => {
                           //  console.log(e.sort)
                           setSort(e.sort)

                       }} onFilterChange={(e) => {
                    // console.log(e)
                    //
                    //     const nameFilters = e.filter.filters.filter(f=>f.field==='fullName')
                    //     const nameFilter = {filters:nameFilters, logic:'or'}
                    //     const otherFilters = filter.filters.filter(f=>f.field)
                    //    const newFilter = {filters:[nameFilter], logic:"and"}
                    //
                    //    console.log({nameFilters,newFilter,otherFilters})

                    setNameFilter(e.filter)


                    const otherFilters = (filter && filter.filters && filter.filters.filter(f => f.field)) || []
                    if (e.filter.filters && e.filter.filters.length)
                        setFilter({filters: [...otherFilters, e.filter], logic: "and"})
                    else setFilter({filters: [...otherFilters], logic: "and"})


                }}
                >
                    <GridToolbar>
                        <div onClick={event => setEditID(null)}>
                            <Grid container>
                                <Grid item md={2} container direction='column'>
                                    <KButton
                                        icon="refresh"
                                        title="Re-Run"
                                        className="k-info-colored"
                                        onClick={refresh}
                                    >
                                        ReFresh
                                    </KButton>

                                    {props.companyMateId && props.companyMateId !== 'null' && props.companyMateId === 'deweto' &&
                                    <SyncMatesDialog firstRun={firstRun}/>}


                                    <KButton
                                        icon="reset"
                                        title="Reset"
                                        className="k-error-colored"
                                        onClick={reset}
                                    >
                                        Reset
                                    </KButton>
                                    {props.isEdit&&<>

                                        <FormControlLabel
                                            control={<Checkbox checked={markAllSent} onChange={e => {
                                                setMarkAllSent(e.target.checked)
                                                return handleFieldChange('emailSent', e.target.checked)
                                            }
                                            }  />}

                                            label="Mark all as Sent"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={markAllPublished} onChange={e => {
                                                setMarkAllPublished(e.target.checked)
                                                return handleFieldChange('publishId', e.target.checked ? -1 : 0)
                                            }} />}
                                            label="Mark all as Published"
                                        />
                                    </>}




                                </Grid>

                                {/*<KButton icon="folder" className="k-info-colored" >Browse</KButton>*/}
                                <Grid item md={1}>
                                    <KButton
                                        icon="excel"
                                        title="Export to Excel"
                                        className="k-success-colored"
                                        onClick={exportToExcel}
                                    >
                                        Export to Excel
                                    </KButton>
                                    <MergePayrollDialog payrollRun={props.runPayroll} id={id}/>

                                </Grid>

                                <Grid item md={3} container direction='column'>
                                    <SetTimeSheetDialog site={site} payrolls={props.runPayroll.payrolls}
                                                        payrollDate={props.runPayroll.payrollDate}
                                                        periodStart={props.runPayroll.periodStart}
                                                        periodEnd={props.runPayroll.periodEnd} payrollData={payrollData}
                                                        OvertimeTypes={OvertimeTypes}/>
                                    <DistEarnings groups={sites} payrollRun={props.runPayroll}
                                                  distEarnings={props.runPayroll.distEarnings}/>
                                    <GroupBonus/>
                                    <ImportEarningsDeductionsFromFile key={"importDeductions"}/>
                                </Grid>


                                <Grid item md={1}>
                                    <div style={{marginLeft:-10}}>
                                        <FormControlLabel
                                            labelPlacement='bottom'
                                            control={<Switch
                                                checked={props.dockVisible}
                                                onChange={props.toggleDockVisible}
                                                value="Summery"
                                                color="primary"
                                                inputProps={{'aria-label': 'secondary checkbox'}}
                                            />
                                            }
                                            label="Summery"
                                        />
                                    </div>

                                </Grid>
                                <Grid item md={2} container direction="column">


                                    <DatePicker
                                        views={["year", "month"]}
                                        label="Payroll Month"
                                        margin="dense"
                                        // minDate={new Date("2018-03-01")}
                                        // maxDate={new Date("2018-06-01")}

                                        value={props.runPayroll.payrollDate}
                                        onChange={payrollDate => props.onPayrollDateChanged({payrollDate})}/>
                                    <TextField margin='dense' multiline label="Description" value={description}
                                               onChange={ev => {
                                                   dispatch({
                                                       type: 'PAYROLL_DESCRIPTION_CHANGED',
                                                       payload: {description: ev.target.value}
                                                   })
                                               }}/>
                                </Grid>

                                <Grid item md={2} container direction="column">
                                    <FormControl margin='dense'>
                                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                            Current Site
                                        </InputLabel>

                                        <Select
                                            value={site}
                                            onChange={ev => {
                                                // setSite(ev.target.value)
                                                dispatch({
                                                    type: 'PAYROLL_SITE_CHANGED',
                                                    payload: {site: ev.target.value}
                                                })

                                                const filters = filter && filter.filters ? filter.filters.filter(f => f.field !== "siteName") : []

                                                if (ev.target.value) {
                                                    filters.push({
                                                        field: "siteName",
                                                        operator: "eq",
                                                        value: ev.target.value
                                                    })
                                                    // const filters = [{field: "siteName", operator: "eq", value: ev.target.value}]
                                                    // employmentType&&filters.push({field: "employmentType", operator: "eq", value: employmentType})
                                                    // setFilter({filters,logic:"and"});

                                                }
                                                // else {
                                                //     const filters = filter.filters.filter(f=>f.field!=="siteName")
                                                //     setFilter({filters,logic:"and"});
                                                //   //  setFilter(employmentType?{filters:[{field: "employmentType", operator: "eq", value: employmentType}],logic:"and"}:undefined);
                                                // }


                                                setFilter({filters, logic: "and"});
                                                // setSite(ev.target.value)
                                                // handelSort(sort.field,payrollRun.payrolls.filter(p=>ev.target.value===0||p.siteName===ev.target.value))
                                                // // payrollRun.filteredPayrolls=filteredPayrolls
                                                //   props.onChange({type:ev.target.value,hrs})
                                            }}
                                        >
                                            <MenuItem key={0} value={null}>All Sites</MenuItem>
                                            {sites && sites.map(ot => <MenuItem key={ot.id}
                                                                                value={ot.name}>{ot.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl margin='dense'>
                                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                            Employment Type
                                        </InputLabel>

                                        <Select
                                            value={employmentType}
                                            onChange={ev => {
                                                // setSite(ev.target.value)
                                                dispatch({
                                                    type: 'PAYROLL_EMPLOYMENT_TYPE_CHANGED',
                                                    payload: {employmentType: ev.target.value}
                                                })
                                                const filters = filter && filter.filters ? filter.filters.filter(f => f.field !== "employmentType") : []

                                                if (ev.target.value) {

                                                    filters.push({
                                                        field: "employmentType",
                                                        operator: "eq",
                                                        value: ev.target.value
                                                    })
                                                    // const filters = [{field: "employmentType", operator: "eq", value: ev.target.value}]
                                                    // site&&filters.push({field: "siteName", operator: "eq", value: site})
                                                    //  setFilter({filters,logic:"and"});

                                                }
                                                setFilter({filters, logic: "and"});
                                                // else {
                                                //
                                                //    // setFilter(site?{filters:[{field: "siteName", operator: "eq", value: site}],logic:"and"}:undefined);
                                                // }


                                                // setSite(ev.target.value)
                                                // handelSort(sort.field,payrollRun.payrolls.filter(p=>ev.target.value===0||p.siteName===ev.target.value))
                                                // // payrollRun.filteredPayrolls=filteredPayrolls
                                                //   props.onChange({type:ev.target.value,hrs})
                                            }}
                                        >
                                            <MenuItem key={0} value={null}>All Employees</MenuItem>
                                            {employmentTypes.map(ot => <MenuItem key={ot.id}
                                                                                 value={ot.name}>{ot.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item md={1} container direction="column">
                                    <FormControl margin='dense'>
                                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                            Status
                                        </InputLabel>

                                        <Select
                                            value={payrollStatus}
                                            onChange={ev => {
                                                // setSite(ev.target.value)
                                                dispatch({
                                                    type: 'PAYROLL_STATUS_CHANGED',
                                                    payload: {status: ev.target.value}
                                                })

                                                const filters = filter.filters ? filter.filters.filter(f => f.field !== "status") : []

                                                if (ev.target.value) {

                                                    filters.push({
                                                        field: "status",
                                                        operator: "eq",
                                                        value: ev.target.value
                                                    })
                                                    // employmentType&&filters.push({field: "employmentType", operator: "eq", value: employmentType})
                                                    // site&&filters.push({field: "siteName", operator: "eq", value: site})
                                                    // setFilter({filters,logic:"and"});

                                                }
                                                // else {
                                                //     const filters = filter.filters.filter(f=>f.field!=="status")
                                                //     // employmentType&&filters.push({field: "employmentType", operator: "eq", value: employmentType})
                                                //     // site&&filters.push({field: "siteName", operator: "eq", value: site})
                                                //   //  setFilter({filters,logic:"and"});
                                                // }
                                                setFilter({filters, logic: "and"});

                                                // setSite(ev.target.value)
                                                // handelSort(sort.field,payrollRun.payrolls.filter(p=>ev.target.value===0||p.siteName===ev.target.value))
                                                // // payrollRun.filteredPayrolls=filteredPayrolls
                                                //   props.onChange({type:ev.target.value,hrs})
                                            }}
                                        >
                                            <MenuItem key={0} value={null}>All Status</MenuItem>
                                            {payrollStates.map(ot => <MenuItem key={ot.id}
                                                                               value={ot.name}>{ot.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl margin='dense'>
                                        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                            Cost Center
                                        </InputLabel>

                                        <Select
                                            value={costCenter}
                                            onChange={ev => {
                                                // setSite(ev.target.value)
                                                dispatch({
                                                    type: 'PAYROLL_COST_CENTER_CHANGED',
                                                    payload: {costCenter: ev.target.value}
                                                })


                                                const filters = filter && filter.filters ? filter.filters.filter(f => f.field !== "costCenterCode") : []

                                                if (ev.target.value) {

                                                    filters.push({
                                                        field: "costCenterCode",
                                                        operator: "eq",
                                                        value: ev.target.value
                                                    })
                                                    // const filters = [{field: "employmentType", operator: "eq", value: ev.target.value}]
                                                    // site&&filters.push({field: "siteName", operator: "eq", value: site})
                                                    //  setFilter({filters,logic:"and"});

                                                }
                                                setFilter({filters, logic: "and"});
                                                // else {
                                                //
                                                //    // setFilter(site?{filters:[{field: "siteName", operator: "eq", value: site}],logic:"and"}:undefined);
                                                // }


                                                // setSite(ev.target.value)
                                                // handelSort(sort.field,payrollRun.payrolls.filter(p=>ev.target.value===0||p.siteName===ev.target.value))
                                                // // payrollRun.filteredPayrolls=filteredPayrolls
                                                //   props.onChange({type:ev.target.value,hrs})
                                            }}
                                        >
                                            <MenuItem key={0} value={null}>All Cost Centers</MenuItem>
                                            {costCenters && costCenters.map(ot => <MenuItem key={ot.id}
                                                                                            value={ot.code || ot.name}>{ot.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>

                        </div>
                    </GridToolbar>
                    <Column field="orgID" width={80} title="Work ID" editable={false}/>
                    <Column field="fullName" className="fullName" width={200} title="Name" editable={false}
                            footerCell={props => <TotalCell {...props} type="count"/>}
                            columnMenu={props => <GridColumnMenuCheckboxFilter
                                data={payrollData.filter(p => !site || p.siteName === site)}
                                expanded={true} {...props}/>}/>
                    <Column field="siteName" title="Current Site" editable={false}/>
                    <Column field="basicSalary" format="{0:n2}" title="Basic Salary" footerCell={TotalCell}
                            cell={MultiCurrencyCell} editable={false}/>

                    <Column field="additionalEarningsTotal" format="{0:n2}" title="Additional Earnings"
                            footerCell={TotalCell} cell={MultiCurrencyCell} editable={false}/>
                    <Column field="totalDeductionIncludingCash" title="Deductions with Settlements" format="{0:n2}"
                            editable={false} footerCell={TotalCell} cell={MultiCurrencyCell}/>
                    <Column field="cash" title="NetPay" format="{0:n2}" editable={false} footerCell={TotalCell}
                            cell={MultiCurrencyCell}/>
                    {/*<Column field="daysWorked" title="Days Worked" editor="numeric"  />*/}
                    {/*<Column field="place" title="Place" editor="text"   />*/}
                </KGrid>


            </div>
        </MultiCurrencyContext.Provider>
    );
};
const mapStateToProps = function (state) {
    return {
        runPayroll: state.runPayroll,
        loading: state.admin.loading,
        dockVisible: state.ui.payrollDockVisible,
        companyName: state.user.currentCompany.name,
        companyMateId: state.user.currentCompany.mateId,
        templateEarnings: state.payrollStatus.templateEarnings,
        isEdit: !!state.runPayroll.createdBy

    }
}

const mapDispatchToProps = function (dispatch) {
    return {
        onItemChange: (event) => {
            dispatch({type: 'PAYROLL_ITEM_CHANGED', payload: {event}})
        },
        onExpandChange: (event) => {
            dispatch({type: 'PAYROLL_EXPAND_CHANGED', payload: {event}})
        },
        toggleDockVisible: (event) => {
            dispatch({type: 'SET_PAYROLL_DOCK_VISIBLE', payload: event.target.checked})
        },
        onPayrollDateChanged: (dates) => {
            // console.log(date)
            dispatch({type: 'CHANGE_PAYROLL_DATE', payload: dates})
        },


    }
}

const ConnectedDetail = connect(state => ({
    overtimeTypes: state.admin.customQueries['{"type":"GET_LIST","resource":"overtimeTypes"}']['data'],
    isApproved: !!state.runPayroll.approvedBy,
    isEdit: !!state.runPayroll.createdBy

}), mapDispatchToProps)(DetailPayroll)

export default connect(mapStateToProps, mapDispatchToProps)(RunPayroll);
