import {all, call, put, takeLatest} from 'redux-saga/effects';
import {fetchEnd, fetchStart, refreshView, showNotification} from 'react-admin';
import OdataServiceProvider from "../Helpers/odataServiceProvider"
import _ from "lodash";
import {select} from "@redux-saga/core/effects";

export default function* runPayrollSaga() {

    yield takeLatest('CHANGE_PAYROLL_DATE', function* (action){
        const {payload} = action;
        yield put({type:'PAYROLL_DATE_CHANGED',payload})
        yield put({type: 'LOAD_PAYROLL_EMPLOYEES',payload: {refresh:true}})
    } )


    yield takeLatest('LOAD_PAYROLL_EMPLOYEES', function* (action) {
        const {payload} = action;
const showLoading = !(payload&&payload.skipLoading)
     if(showLoading)  {
         yield put(fetchStart());
     }

 try{

        const [employees, incomeTaxRules,company,banks,costCenters] = yield all([
            call(OdataServiceProvider.getList,'employees',{pagination:{},sort:{},filter:{filter:'active eq true',expand:`earnings,deductions,expenses,position($expand=department,jobLevel),department,costCenter,site,leaves,loans($filter=LoanStatus eq 'Standing')`}}),
            call(OdataServiceProvider.getList,'incomeTaxRules',{pagination:{},sort:{},filter:{}}),
            call(OdataServiceProvider.getOne,'companies',{id:localStorage.getItem('companyId'),expand:`companySettings`}),
            // call(OdataServiceProvider.getList,'currencyRates',{pagination:{},sort:{},filter:{queryString:'$orderby=Date desc &$top=1'}}),
            call(OdataServiceProvider.getList,'companyBanks',{filter:{expand:`bank`}}),
            call(OdataServiceProvider.getList,'costCenters')


        ])
     const currencyRate = yield select(state=>state.currency.conversionRates);
    const usedCurrencyRates = _.omit(currencyRate,["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId", "isDeleted"])
      // const response = yield call(OdataServiceProvider.getList,'employees',{pagination:{},sort:{},filter:{expand:'payrollFixedEarnings,payrollFixedDeductions'}})
       // console.log(response)
       // if(payload==='reload')
       // {
       //     yield put({
       //         type: 'PAYROLL_EMPLOYEES_RELOADED',
       //         payload: {reload:true,employees,incomeTaxRules,company,currencyRate:currencyRate.data,banks},
       //     });
       // } else {
       //
       //
       // }
    console.log({usedCurrencyRates})
     yield put({
         type: 'PAYROLL_EMPLOYEES_LOADED',
         payload: {employees,incomeTaxRules,company,currencyRate,usedCurrencyRates,banks,refresh:payload&&payload.refresh,costCenters},
     });

     if(showLoading)  {
         yield put(fetchEnd())
     }


     }
        catch (e) {
            console.log("error",e)
            yield put(showNotification(`Unable to load payroll data, make sure you are connected to the server`, "error"))
            if(showLoading)  {
                yield put(fetchEnd())
            }
        }

    })

    yield takeLatest('SAVE_PAYROLL_RUN', function* (action) {
//console.log("saving payroll")
        const {payload} = action
       try {
            const data = _.omit(payload,["site"])
           //console.log(data)
           const payrollRun = yield call(OdataServiceProvider.create,'payrollRuns',{data})
           yield put({
               type: 'PAYROLL_RUN_SAVED',
               payload: {payrollRun},
           });
       }
       catch (e) {
           yield put({
               type: 'PAYROLL_RUN_SAVE_FAILED'
           });
           yield put(fetchEnd())
       }
        // const [employees, incomeTaxRules,company] = yield all([
        //     call(OdataServiceProvider.getList,'employees',{pagination:{},sort:{},filter:{expand:'earnings,deductions'}}),
        //     call(OdataServiceProvider.getList,'incomeTaxRules',{pagination:{},sort:{},filter:{}}),
        //     call(OdataServiceProvider.getOne,'companies',{id:1,expand:'earnings,deductions'}),
        // ])
        //
        // // const response = yield call(OdataServiceProvider.getList,'employees',{pagination:{},sort:{},filter:{expand:'payrollFixedEarnings,payrollFixedDeductions'}})
        // // console.log(response)
        // yield put({
        //     type: 'PAYROLL_EMPLOYEES_LOADED',
        //     payload: {employees,incomeTaxRules,company},
        // });
    })

    yield takeLatest('PAYROLL_RUN_APPROVE', function* (action) {

        const {payload} = action

        try {
           // const res = yield call(OdataServiceProvider.getOne,'payrollRuns',{id:payload.id})


            // const m = new Date(payload.data.payrollDate).getMonth()
            // const year = new Date(payload.data.payrollDate).getFullYear()

            yield put(fetchStart());
            //move to server
            // try {
            //
            //    const oldApproved =  yield call(OdataServiceProvider.getOne, 'approvedPayrollRuns', {id:+`${year}${m}`})
            //     yield call(OdataServiceProvider.update, 'approvedPayrollRuns', {id:+`${year}${m}`,data: {payrollRunId:payload.id,approvedDate:new Date()}})
            //     yield call(OdataServiceProvider.update, 'payrollRuns', {id:oldApproved.data.payrollRunId,data:{status:'Denounced'}})
            // }
            // catch (e) {
            //     console.log(e)
            //     yield call(OdataServiceProvider.create, 'approvedPayrollRuns', {data: {id:+`${year}${m}`,payrollRunId:payload.id,approvedDate:new Date()}})
            // }
            // yield call(OdataServiceProvider.update, 'payrollRuns', {id:payload.data.id,data:{status:'Approved'}})

            const res = yield call(OdataServiceProvider.serverAction, 'payrollRuns', {action:'approve',id:payload.data.id})

            yield put(fetchEnd())

            yield put(showNotification('Payroll Approved', "info"))

            yield put(refreshView())

            yield put({
                type: 'PAYROLL_RUN_APPROVED',
                payload: {payload},
            });
        }
        catch (e) {
            yield put({
                type: 'PAYROLL_RUN_APPROVE_FAILlED'
            });

            const message = e.body.value;

            yield put(showNotification(`Payroll approval failed`, "error"))
            yield put(fetchEnd())
        }
        // const [employees, incomeTaxRules,company] = yield all([
        //     call(OdataServiceProvider.getList,'employees',{pagination:{},sort:{},filter:{expand:'earnings,deductions'}}),
        //     call(OdataServiceProvider.getList,'incomeTaxRules',{pagination:{},sort:{},filter:{}}),
        //     call(OdataServiceProvider.getOne,'companies',{id:1,expand:'earnings,deductions'}),
        // ])
        //
        // // const response = yield call(OdataServiceProvider.getList,'employees',{pagination:{},sort:{},filter:{expand:'payrollFixedEarnings,payrollFixedDeductions'}})
        // // console.log(response)
        // yield put({
        //     type: 'PAYROLL_EMPLOYEES_LOADED',
        //     payload: {employees,incomeTaxRules,company},
        // });
    })

    yield takeLatest('PAYROLL_RUN_UNAPPROVE', function* (action) {

        const {payload} = action

        try {
            // const res = yield call(OdataServiceProvider.getOne,'payrollRuns',{id:payload.id})


            // const m = new Date(payload.data.payrollDate).getMonth()
            // const year = new Date(payload.data.payrollDate).getFullYear()

            yield put(fetchStart());
            //move to server
            // try {
            //
            //    const oldApproved =  yield call(OdataServiceProvider.getOne, 'approvedPayrollRuns', {id:+`${year}${m}`})
            //     yield call(OdataServiceProvider.update, 'approvedPayrollRuns', {id:+`${year}${m}`,data: {payrollRunId:payload.id,approvedDate:new Date()}})
            //     yield call(OdataServiceProvider.update, 'payrollRuns', {id:oldApproved.data.payrollRunId,data:{status:'Denounced'}})
            // }
            // catch (e) {
            //     console.log(e)
            //     yield call(OdataServiceProvider.create, 'approvedPayrollRuns', {data: {id:+`${year}${m}`,payrollRunId:payload.id,approvedDate:new Date()}})
            // }
            // yield call(OdataServiceProvider.update, 'payrollRuns', {id:payload.data.id,data:{status:'Approved'}})

            const res = yield call(OdataServiceProvider.serverAction, 'payrollRuns', {action:'unApprove',id:payload.data.id})

            yield put(fetchEnd())

            yield put(showNotification('Payroll UnApproved', "info"))

            yield put(refreshView())

            yield put({
                type: 'PAYROLL_RUN_UNAPPROVED',
                payload: {payload},
            });
        }
        catch (e) {
            yield put({
                type: 'PAYROLL_RUN_APPROVE_FAILlED'
            });

            const message = e.body.value;

            yield put(showNotification(`Payroll unApproval failed`, "error"))
            yield put(fetchEnd())
        }
        // const [employees, incomeTaxRules,company] = yield all([
        //     call(OdataServiceProvider.getList,'employees',{pagination:{},sort:{},filter:{expand:'earnings,deductions'}}),
        //     call(OdataServiceProvider.getList,'incomeTaxRules',{pagination:{},sort:{},filter:{}}),
        //     call(OdataServiceProvider.getOne,'companies',{id:1,expand:'earnings,deductions'}),
        // ])
        //
        // // const response = yield call(OdataServiceProvider.getList,'employees',{pagination:{},sort:{},filter:{expand:'payrollFixedEarnings,payrollFixedDeductions'}})
        // // console.log(response)
        // yield put({
        //     type: 'PAYROLL_EMPLOYEES_LOADED',
        //     payload: {employees,incomeTaxRules,company},
        // });
    })


    yield takeLatest('LOAD_PAYROLLRUN', function* (action){
        const {payload} = action
        yield put(fetchStart());
        try {
//             const [payrollRun, incomeTaxRules] = yield all([
//             call(OdataServiceProvider.getList,'incomeTaxRules',{pagination:{},sort:{},filter:{}}),
//             call(OdataServiceProvider.getOne,'payrollRuns',{id:payload.id,expand:`incomeTaxRules,payrolls($expand= overtimes, bonuses,payrollFixedExpenses,payrollFixedDeductions,payrollFixedEarnings, payrollDeductions, loans)`})
// ])
          const payrollRun = yield  call(OdataServiceProvider.getOne,'payrollRuns',{id:payload.id,expand:`incomeTaxRules,payrolls($expand= overtimes, bonuses,payrollFixedExpenses,payrollFixedDeductions,payrollFixedEarnings, payrollDeductions, employee($expand=loans($filter=LoanStatus eq 'Standing')))`})

            // let useServiceHours = payrollRun.data.useServiceHours
            // let deductServiceHour = payrollRun.data.deductServiceHour
            //
            // const company = yield call(OdataServiceProvider.getOne,'companies',{id:localStorage.getItem('companyId'),expand:`companySettings`})
            //
            // let
            //
            // const useServiceHoursSetting = company.data.companySettings.find(s=>s.name==="useServiceHour");
            // const deductServiceHourSetting = company.data.companySettings.find(s=>s.name==="deductServiceHour");


            const data =  _.omit(payrollRun.data, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","companyId"]) ; // (({id, createdDate, modifiedDate,version,'@odata.context' ...others}) => ({...others}))(params.data)
            data.payrolls = data.payrolls.map(p => {
               // p.emailSent = false

                p.useServiceHours = payrollRun.data.useServiceHours
                p.distEarningsOnActualDate = payrollRun.data.distEarningsOnActualDate
                p.deductServiceHour = payrollRun.data.deductServiceHour
                p.daysInMonthForExpectedHours = payrollRun.data.daysInMonthForExpectedHours
                p.daysInMonthForOT = payrollRun.data.daysInMonthForOT
                p.bonuses = p.bonuses.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.overtimes = p.overtimes.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.payrollFixedExpenses = p.payrollFixedExpenses.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.payrollFixedDeductions= p.payrollFixedDeductions.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.payrollFixedEarnings = p.payrollFixedEarnings.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.payrollDeductions = p.payrollDeductions.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.loans = p.employee.loans
                p.startDate = p.employee.startDate
                p.contractEndDate = p.employee.contractEndDate
                p.additionalFixedEarningsOriginal = p.payrollFixedEarnings.reduce((sum, cv) => sum + cv.originalValue, 0)
                // p.runDate = new Date();
                // console.log(p.payrollFixedDeductions)
                return _.omit(p, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId", "employee"])
            }); // (({id, createdDate, )
            data.incomeTaxRules= data.incomeTaxRules.map(p =>_.omit(p, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId"]) ); // (({id, createdDate, )
            data.runDate = new Date();
            data.status = "Waiting"
            data.locked = false

            yield put({
                type: 'PAYROLLRUN_DATA_LOADED',
                payload: {payrollRun:data},
            });

                yield put(fetchEnd())
        }catch (e) {
            yield put(showNotification(`Sorry, Failed to load the payroll `, "error"))
            yield put(fetchEnd())
        }
    })

    yield takeLatest('MERGE_WITH_PAYROLL', function* (action){
        const {payload} = action
        yield put(fetchStart());
        try {
//             const [payrollRun, incomeTaxRules] = yield all([
//             call(OdataServiceProvider.getList,'incomeTaxRules',{pagination:{},sort:{},filter:{}}),
//             call(OdataServiceProvider.getOne,'payrollRuns',{id:payload.id,expand:`incomeTaxRules,payrolls($expand= overtimes, bonuses,payrollFixedExpenses,payrollFixedDeductions,payrollFixedEarnings, payrollDeductions, loans)`})
// ])
            const payrollRun = yield  call(OdataServiceProvider.getOne,'payrollRuns',{id:payload.id,expand:`incomeTaxRules,payrolls($expand= overtimes, bonuses,payrollFixedExpenses,payrollFixedDeductions,payrollFixedEarnings, payrollDeductions, employee($expand=loans($filter=LoanStatus eq 'Standing')))`})

            // let useServiceHours = payrollRun.data.useServiceHours
            // let deductServiceHour = payrollRun.data.deductServiceHour
            //
            // const company = yield call(OdataServiceProvider.getOne,'companies',{id:localStorage.getItem('companyId'),expand:`companySettings`})
            //
            // let
            //
            // const useServiceHoursSetting = company.data.companySettings.find(s=>s.name==="useServiceHour");
            // const deductServiceHourSetting = company.data.companySettings.find(s=>s.name==="deductServiceHour");


            const data =  _.omit(payrollRun.data, ["id", "createdDate", "modifiedDate", "version", "@odata.context", "@odata.etag","companyId"]) ; // (({id, createdDate, modifiedDate,version,'@odata.context' ...others}) => ({...others}))(params.data)
            data.payrolls = data.payrolls.map(p => {
                // p.emailSent = false
                p.useServiceHours = payrollRun.data.useServiceHours
                p.deductServiceHour = payrollRun.data.deductServiceHour
                p.daysInMonthForExpectedHours = payrollRun.data.daysInMonthForExpectedHours
                p.daysInMonthForOT = payrollRun.data.daysInMonthForOT
                p.bonuses = p.bonuses.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.overtimes = p.overtimes.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.payrollFixedExpenses = p.payrollFixedExpenses.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.payrollFixedDeductions= p.payrollFixedDeductions.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.payrollFixedEarnings = p.payrollFixedEarnings.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.payrollDeductions = p.payrollDeductions.map(o=>_.omit(o, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId" ]) );
                p.loans = p.employee.loans
                p.additionalFixedEarningsOriginal = p.payrollFixedEarnings.reduce((sum, cv) => sum + cv.originalValue, 0)
                // p.runDate = new Date();
                // console.log(p.payrollFixedDeductions)
                return _.omit(p, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId", "employee"])
            }); // (({id, createdDate, )
            // data.incomeTaxRules= data.incomeTaxRules.map(p =>_.omit(p, ["id", "createdDate", "createdBy",'modifiedBy',"modifiedDate", "version", "@odata.context", "@odata.etag","companyId"]) ); // (({id, createdDate, )
            // data.runDate = new Date();
            // data.status = "Waiting"
            // data.locked = false

            yield put({
                type: 'PAYROLLRUN_LOADED_FOR_MERGE',
                payload: {payrollRun:data},
            });

            yield put(fetchEnd())
        }catch (e) {
            yield put(showNotification(`Sorry, Failed to load the payroll `, "error"))
            yield put(fetchEnd())
        }
    })

    yield takeLatest('SAVE_DEFAULT_DIST_EARNINGS', function* (action){
        const {payload} = action
        const defaultDistEarnings = payload.map(e=>({...e,baseAmount:0,applied:false,appliesTo:{...e.appliesTo,emps:[]}}))

        try {
            // yield put(fetchStart());
            yield call(OdataServiceProvider.update, 'companies', {id:localStorage.getItem('companyId'),data: {defaultDistEarnings}})
            yield put(showNotification('Distributed Earnings Saved for Next Payroll Runs', "info"))
            // yield put(fetchEnd())
        }
        catch (e) {
            yield put(showNotification(`Cannot not save, please check the data and try again later`, "error"))
            // yield put(fetchEnd())
        }



    })

    yield takeLatest('APPLY_GROUP_BONUSES', function* (action){
        const {payload} = action
        yield put(fetchStart());
        try {
//             const [payrollRun, incomeTaxRules] = yield all([
//             call(OdataServiceProvider.getList,'incomeTaxRules',{pagination:{},sort:{},filter:{}}),
//             call(OdataServiceProvider.getOne,'payrollRuns',{id:payload.id,expand:`incomeTaxRules,payrolls($expand= overtimes, bonuses,payrollFixedExpenses,payrollFixedDeductions,payrollFixedEarnings, payrollDeductions, loans)`})
// ])
            //const payrollRuns = yield  call(OdataServiceProvider.getOne,'payrollRuns',{id:payload.id,expand:`incomeTaxRules,payrolls($expand= overtimes, bonuses,payrollFixedExpenses,payrollFixedDeductions,payrollFixedEarnings, payrollDeductions, employee($expand=loans($filter=LoanStatus eq 'Standing')))`})

            const payrolls = yield  call(OdataServiceProvider.getList,'payrolls', { filter:{filter:`payrollRunId in (${payload.payrollRuns.map(x => "'" + x + "'").toString()})`} })

            const bonusesLookup = Object.fromEntries(payload.bonuses.map((b) => [b.name.replace(/\s+/g, '').toLowerCase(), b.value]))

            // console.log({payrolls})
            const pr = payrolls.data.map(p=>{


                    p.bonusRate = bonusesLookup[p.fullName.replace(/\s+/g, '').toLowerCase()]
                    p.bonusScale = payload.scale || 1

                return p;
            })



            // let useServiceHours = payrollRun.data.useServiceHours
            // let deductServiceHour = payrollRun.data.deductServiceHour
            //
            // const company = yield call(OdataServiceProvider.getOne,'companies',{id:localStorage.getItem('companyId'),expand:`companySettings`})
            //
            // let
            //
            // const useServiceHoursSetting = company.data.companySettings.find(s=>s.name==="useServiceHour");
            // const deductServiceHourSetting = company.data.companySettings.find(s=>s.name==="deductServiceHour");

            // console.log({pr})


            yield put({
                type: 'GROUP_BONUS_CHANGED',
                payload: {payrolls:_.groupBy(pr.filter(p=>p.bonusRate),'employeeId'), lastDate:payload.lastDate},
            });

            yield put(fetchEnd())
        }catch (e) {
            console.log(e)
            yield put(showNotification(`Sorry, Failed to load the payroll `, "error"))
            yield put(fetchEnd())
        }
    })


}
